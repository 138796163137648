<template>
    <v-container>
        <AConfirmation :isShow="isPending" @confirm="submit" @cancel="cancelSubmit" />
        <ASuccessFour :api="this.api"/>
        <AError
            :api="this.api"/>
        <v-row class="mt-3">
            <v-col align="center" justify="center" cols="12" sm="8" class="mx-auto">
                <v-col align="start" justify="start">
                    <v-btn @click="redirectBack" class="mb-3" color="primary">
                        <v-icon>
                            mdi-arrow-left-bold
                        </v-icon>
                    </v-btn>
                </v-col>
                <v-skeleton-loader
                ref="skeleton"
                type="table"
                v-if="api.isLoading">

                </v-skeleton-loader>
                <v-card class="pa-3" elevation-1 v-else>
                    <v-toolbar class="white--text text-h5 mb-4" color="secondary">
                        Assign new Person in charge
                    </v-toolbar>
                   
                    <!-- <ASuccessThree
                    :api="this.api"/> -->
                    <div v-if="isSelectingCompany">
                        <div class="d-flex justify-start" v-if="isSelectingCompany">
                            <v-text-field dense outlined disabled v-model = "form.company.name">

                            </v-text-field>
                        </div>
                        <div class="d-flex justify-start">
                            <v-autocomplete 
                            deletable-chips
                            small-chips
                            multiple
                            item-text="staff_name"
                            v-model="form.pic" :items="picOptions" dense outlined
                            label="Person in charge" return-object>
                                <template v-slot:item="{ item }">
                                    <v-list-tile-content>
                                        <v-list-tile-title>
                                           {{ item.staff_name }} ( {{ item.role.replaceAll('_',' ') }})
                                        </v-list-tile-title>
                                    </v-list-tile-content>
                                </template>
                            </v-autocomplete>
                        </div>
                        <!-- <div class="d-flex justify-start">
                            <v-checkbox class="pt-0 mt-0" v-model="form.notify" label="Do you want to ask pic follow-up with the client ?">

                            </v-checkbox>
                        </div> -->
                        <div class="d-flex justify-end">
                            <v-btn color="primary" @click="redirectBack" plain>
                                Cancel
                            </v-btn>
                            <v-btn :loading="api.isLoading" @click="validateInput" color="primary">
                                Confirm
                            </v-btn>
                        </div>
                    </div>

                    <!--BOC : Create a form here-->
                    <!--EOC-->
                    
                </v-card>
            </v-col>
        </v-row>
    </v-container>
</template>
  
<script>
import { mapState } from 'vuex'
// import ASuccessThree from '../../components/common/ASuccessThree.vue';
import AConfirmation from '../../components/common/AConfirmation.vue';
import ASuccessFour from '../../components/common/ASuccessFour.vue';
export default {
    components: {
    
    AConfirmation,
    ASuccessFour
},
    computed: mapState({
        //
    }),
    props: [
        //
    ],
    data: () => ({
        isPending:false,
        api: {
            isSuccesful: false,
            isError: false,
            isLoading: false,
            error: null,
            url: null,
            success:null,
        },
        form:{
            notify:null,
            pic: null,
            company: null,

        
        },
        picOptions:null,
        filteredPicOptions:null,
        companyOptions:null,
        isSelectingCompany: false,
        isSelectingPIC: false,
    }),
    created() {
        this.api.callbackReset = () => {
            this.api.isLoading = true;
            this.api.isError = false;
        };
        this.api.callbackError = (e) => {
            this.api.isLoading = false;
            this.api.isError = true;
            this.api.error = e;
        };
        this.api.callbackSuccess = (resp) => {
            if(resp.class=="getUserMultipleRole"){
                this.picOptions = resp.data;
            }
            if(resp.class=="getAllCompany"){
                this.companyOptions = resp.data.companies;
            }
            // if(resp.class=="addPIC"){
            //     this.api.isSucessful = true;
            //     this.api.success = "Succesfuly assigned PIC"
            // }
            if(resp.class=="getCompanyPIC"){
                this.form.pic = resp.data.pic;
                this.form.company = resp.data.company
                // this.form.pic = [resp.data.staff_name,resp.data.role];
                // this.form.company = resp.data.name;
            }
            if(resp.class=="updatePIC"){
                this.api.isSuccesful = true;
                this.api.success = "Succesfuly update the person-in-charge";
            }
            this.api.isLoading = false;
            this.api.isError = false;
        }

    },
    mounted() {
        this.fetch();
    },
    methods: {
        fetch() {
            this.isSelectingCompany = true;
            let fetchPICApi = this.fetchPIC();
            let fetchCompaniesApi = this.fetchCompanies();
            let fetchCompaniesPICApi = this.fetchCompaniesPIC();
            this.$axios.all([
                this.$api.fetch(fetchCompaniesPICApi),
                this.$api.fetch(fetchPICApi),
                this.$api.fetch(fetchCompaniesApi),
            ])
        },
        fetchCompaniesPIC(){
            let companyId = this.$router.history.current.params.id; 
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic/company/"+companyId;
            return tempApi;
        },  
        fetchPIC(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "POST";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/user/role/multiple";
            tempApi.params={
                'roles':["salesperson","customer_service","vision_admin","sales_team_lead","sales_manager"],
            };
            return tempApi;
        },
        fetchCompanies(){
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "GET";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/companies/";
            return tempApi;
        },
        validateInput(){
            this.isPending = true;
        },
        submit() {
            this.isPending = false;
            let createApi = this.create();
            this.$axios.all([
                this.$api.fetch(createApi),
            ]);
        },
        cancelSubmit(){
            this.isPending = false;
        },
        create() {
            let tempApi = this.$_.clone(this.api);
            tempApi.method = "PUT";
            tempApi.url = process.env.VUE_APP_SERVER_API+"/pic";
            tempApi.params = {
                'companyId':this.form.company.id,
                'pic':this.form.pic,
                'notify':this.form.notify,
                'userId':this.$store.getters.getUserId,

            };
           
            return tempApi;
        },
        redirectBack(){
            this.$router.go(-1);
        }
    }
}
</script>